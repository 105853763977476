<template>
  <div class="content">
    <el-form inline :model="search" class="tb-form">
      <el-form-item label="活动商品名称：">
        <el-input v-model="search.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="add">
      <el-button class="tb-button" type="primary" @click="add">
        <i class="tb-icon-plus"/>添加商品
      </el-button>
    </div>
    <div class="tablebox">
      <el-table
        :data="tableData"
        class="tb-table"
        @row-dblclick="toDetail"
      >
        <!-- <el-table-column prop="id" label="ID"/> -->
        <el-table-column prop="sort" label="序号"/>
        <el-table-column prop="product" label="兑换类型"/>
        <el-table-column prop="productName" label="活动商品名"/>
        <el-table-column prop="statusname" label="状态"/>
        <el-table-column prop="updatedAt" label="更新日期"/>
        <el-table-column label="操作">
          <router-link slot-scope="scope" :to="`/integral/add?id=${scope.row.id}`" class="edit">编辑</router-link>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="tb-pagination"
        layout="prev, pager, next"
        :current-page.sync="page.no + 1"
        :page-size="page.size"
        :total="page.total"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntegralList',
  data() {
    return {
      search: {
        name: ''
      },
      tableData: [],
      page: {
        no: 0,
        size: 10,
        total: 0
      }
    }
  },
  mounted() {
    let path = this.$route.path
    if (path.indexOf('/') === 0) path = path.substring(1)
    const currentPage = {
      link: path,
      name: '积分兑换'
    }
    this.$store.commit('changeRoute', currentPage)
    this.getList()
  },
  methods: {
    async getList() {
      let {no, size} = this.page
      let {name} = this.search
      const params = {
        page_size: size,
        page_no: no,
        name
      }
      const url = 'admin/integral/product/list'
      const data = await this.$https.get(url, {params})
      if (!data) return
      data.list.forEach(item => {
        item.statusname = item.status === 1 ? '已生效' : '未生效'
        item.product = '优惠券'
      })
      this.page.total = data.total_count
      this.tableData = data.list
    },
    onSearch() {
      this.page.no = 0
      this.getList()
    },
    add() {
      this.$router.push('/integral/add')
    },
    pageChange(no) {
      this.page.no = no - 1
      this.getList()
    },
    toDetail(row) {
      this.$router.push(`/integral/add?id=${row.id}`)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
